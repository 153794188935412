<template>
<Breadcrumb page-title="Quick Agent Email" page-section="Custom Forms"> </Breadcrumb>
  <section class="content">
    <div class="row">
        <div class="col-md-12">
           <div class="box">
              <div class="box-body">
                      <p>
                        Use this form to send an email to any email address without searching for the contact. If the email address exists your message will be added to their history. If the email address does not exist, a new contact will be created.
                      </p>
                      <div class="nav-tabs-custom">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span> Enter Recipient Details</span></a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-fw fa-exchange"></i><span>Interaction Properties</span></a>
                            </li>
                        
                         </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                                <div class="form-horizontal">  
                                    <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="col-2 control-label text-left">Topic</label>
                                        <div class="col-10">
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Select topic"
                                                label="name"
                                                class="is-invalid"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Customer Success',
                                                    options:  [
                                                        { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                                        { value: '_Feedback', name: '_Feedback',level:'2'},
                                                        { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                                        { value: '_Spam', name: '_Spam',level:'2'},
                                                        { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                                        { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                                        { value: 'Return', name: 'Return',level:'2'},
                                                        { value: 'Return', name: 'Return',level:'2'},
                                                        { value: 'Cancel', name: 'Cancel',level:'2'},
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                            <div class="invalid-feedback"><span>Please select Topic</span></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                            <label class="col-2 control-label text-left">From</label>
                                            <div class="col-10">
                                            <Multiselect
                                                v-model="value"
                                                :closeOnSelect="false"
                                                placeholder="--Select Mailbox--"
                                                :options="{
                                                    customersupport: 'customer support (support@example.com)',
                                                    sales: 'Sales (sales@example.com)',
                                                    technical: 'Technical Support (technical@example.com)'
                                                }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-2 control-label text-left">To</label>
                                            <div class="col-10">
                                                <select class="form-control select2 is-invalid">
                                                <option>Select To Address</option>
                                                <option selected="selected" value="Jacob.Smith@1to1Service.Com">Jacob.Smith@1to1Service.Com</option>
                                                </select>
                                                <div class="invalid-feedback"><span>Address required</span></div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-2 control-label text-left">CC</label>
                                            <div class="col-10">
                                                <input class="form-control" type="text">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-2 control-label text-left">Add Group To CC Line</label>
                                            <div class="col-10">
                                                <Multiselect
                                                v-model="value"
                                                :closeOnSelect="false"
                                                placeholder="Search List to Add Group to CC Line"
                                                :options="{
                                                    Dispatch: 'Dispatch',
                                                    service: 'Service',
                                                }"
                                                />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-2 control-label text-left ">Subject</label>
                                            <div class="col-10">
                                                <input class="form-control is-invalid" type="text" value="looking for low cost tv">
                                                <div class="invalid-feedback"><span>Subject required</span></div>
                                            </div>
                                        </div>
                                        
                                    
                                    </div>
                                    <div class="col-md-12">
                                        <label class="drop-box">
                                        Drop files here or click to upload
                                    
                                        </label>
                                        <div>(Maximum 10 MB upload at one time.)</div>
                                        <div class="imageuploadify">
                                        <!-- ngRepeat: file in files -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="tab-pane" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                                <InteractionPropertyTab></InteractionPropertyTab>
                            </div>
                        </div>
                    </div>
                    <div class="box">
                        <div class="box-header with-border">
                            <h3 class="box-title">Compose Your Message</h3>
                        </div>
                        <div class="box-body">
                            <div class="form-group">
                                <div class="btn-group pull-right" >
                                    <VariableAndStockResponses></VariableAndStockResponses>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div class="form-group text-editor-container mtop-15">
                                <textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor="">&lt;p&gt;Hello,&lt;/p&gt;
                                    &lt;p&gt;I'm looking for s small TV for our bedroom, but don't want to spend more than $100. Do you have any option for me to consider?&lt;/p&gt;

                                    &lt;p&gt;Regards,&lt;/p&gt;

                                    &lt;p&gt;John Doe&lt;/p&gt;
                                </textarea>
                            </div>
                            <label class="control-label text-left"> 
                                <Toggle/> Expect Customer Reply
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                          <button type="button" class="pull-left btn btn-info" id="sendEmail">Send
                            <i class="fa fa-arrow-circle-right"></i></button>
                          </div>
                      </div>
              </div>
            </div>
        </div>
    </div>
  </section>

</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
import iServiceSwitch from '../components/iServiceSwitch.vue';
import NewQuotePropertyForm from '../components/NewQuotePropertyForm.vue';
import QuoteDetail from '../components/QuoteDetail.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DraftBodyTabs from '../components/DraftBodyTabs.vue';
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

export default {
  name: 'Quote Settings',
   components: {
    Breadcrumb,
    Toggle,
    Multiselect,
    InteractionPropertyTab,
    DraftBodyTabs,
    VariableAndStockResponses
  },
  setup(){      
      
       return{};
  }

}
</script>
