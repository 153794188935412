<template>
<div class="nav-tabs-custom">
    <ul class="nav nav-tabs">
        <li class="nav-item"><a class="nav-link active" id="draftbody-tab" data-bs-toggle="tab" data-bs-target="#draftbody" type="button" role="tab" aria-controls="draftbody" aria-selected="true">Draft Body</a></li>
        <li class="nav-item"><a class="nav-link" id="preview-tab" data-bs-toggle="tab" data-bs-target="#plainhtml" type="button" role="tab" aria-controls="preview" aria-selected="true">Preview<br></a></li>
        <div class="btn-group pull-right variable-btn" >
            <VariableAndStockResponses></VariableAndStockResponses>
        </div>
    </ul>
    <div class="tab-content">
        <div class="tab-pane active" id="draftbody">
            <div class="form-group text-editor-container">
                <textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor ><p>Hello,</p>

                    <p>I&#39;m looking for s small TV for our bedroom, but don&#39;t want to spend more than $100. Do you have any option for me to consider?</p>
                    
                    <p>Regards,</p>
                    
                    <p>John Doe</p>
                </textarea>
            </div>

        </div>
        <div class="tab-pane" id="plainhtml">
            <p>Hello,</p>

            <p>I&#39;m looking for s small TV for our bedroom, but don&#39;t want to spend more than $100. Do you have any option for me to consider?</p>
            
            <p>Regards,</p>
            
            <p>John Doe</p>
        </div>										   
    </div>
</div>
</template>

<script>
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

export default {
  name: 'InboxMessageDetailComponents',
   components: {
      VariableAndStockResponses,
  },
  data: function(){
      return{
       quote:true,
       message:false,
       showDetails :false
      }
  },
  mounted() { 
   
  }
 
}
</script>
